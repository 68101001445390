import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import gsap from "gsap/gsap-core";
import { Expo } from "gsap";
import React, { useEffect, useRef } from "react";
import MMSLogo from "../components/MMSLogo";

const useStyles = makeStyles(
  (_theme) => ({
    root: (props) => ({
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
    stretch: {
      flexGrow: 1,
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      display: "flex",
      color: _theme.palette.secondary.light,
    },
  }),
  { name: "LandingPage" }
);

export default function LandingPage(props) {
  const { className } = props;
  const classes = props.classes || {};
  const styles = useStyles(props);
  const nothingToSee = useRef();

  useEffect(() => {
    gsap.fromTo(
      nothingToSee.current,
      {
        scale: 1.1,
        autoAlpha: 0,
        transformOrigin: "center center",
      },
      {
        autoAlpha: 1,
        scale: 1,
        duration: 0.5,
        ease: Expo.easeOut,
        delay: 5,
      }
    );
    return () => {};
  }, []);

  return (
    <div className={clsx(className, classes.root, styles.root)}>
      <div className={styles.stretch}></div>
      <MMSLogo />
      <div className={styles.stretch}>
        <div style={{ flexGrow: 0.2 }} />
        <div ref={nothingToSee}>
          <Typography variant="body1" color="inherit">
            Bitte gehen Sie weiter...
          </Typography>
          <Typography variant="body2" color="inherit">
            hier gibt es nichts zu sehen!
          </Typography>
        </div>
        <div style={{ flexGrow: 0.8 }} />
      </div>
    </div>
  );
}
