import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { gsap } from "gsap";
import reportWebVitals from "./reportWebVitals";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { EasePack } from "gsap/EasePack";

gsap.registerPlugin(CSSRulePlugin, EasePack);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
