import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import gsap from "gsap/gsap-core";
import { Expo, Power3 } from "gsap";
import React, { createRef, useEffect, useRef } from "react";

const useStyles = makeStyles(
  (_theme) => ({
    root: (props) => ({ overflow: "visible" }),
    logo: (props) => ({ maxWidth: "350px", overflow: "visible" }),
  }),
  { name: "MMSLogo" }
);

export default function MMSLogo(props) {
  const { className } = props;
  const classes = props.classes || {};
  const styles = useStyles(props);
  const theme = useTheme();

  const mRef = useRef([createRef(), createRef()]);
  const malkusRef = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);
  const mediaSolutionsRef = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);
  useEffect(() => {
    const mLetters = mRef.current.map((letter) => letter.current);
    const malkusLetters = malkusRef.current.map((letter) => letter.current);
    const mediaSolutionsLetters = mediaSolutionsRef.current.map(
      (letter) => letter.current
    );
    const tl = gsap.timeline({
      defaults: {},
    });
    tl.fromTo(
      mLetters[0],
      {
        scale: 5,
        autoAlpha: 0,
        transformOrigin: "center center",
      },
      {
        autoAlpha: 1,
        scale: 1,
        duration: 0.3,
        ease: Expo.easeOut, //Bounce.easeOut,
      },
      "1"
    );
    tl.fromTo(
      mLetters[1],
      {
        scale: 2,
        autoAlpha: 0,
        transformOrigin: "center center",
      },
      {
        autoAlpha: 1,
        scale: 1,
        duration: 0.3,
        ease: Power3.easeOut,
      },
      "1.1"
    );
    tl.fromTo(
      mLetters[0],
      {
        fill: theme.palette.primary.main,
      },
      {
        fill: theme.palette.secondary.main,
        duration: 1.5,
        ease: Power3.easeOut,
      },
      "1"
    );
    tl.fromTo(
      mLetters[1],
      {
        fill: theme.palette.primary.main,
      },
      {
        fill: theme.palette.secondary.main,
        duration: 1.5,
        ease: Power3.easeOut,
      },
      "1"
    );
    tl.fromTo(
      malkusLetters,
      { autoAlpha: 0, scale: 1.8, transformOrigin: "center center" },
      {
        autoAlpha: 1,
        scale: 1,
        duration: 0.2,
        ease: "sine.inOut",
        stagger: {
          grid: [1, 6],
          from: "center",
          amount: 0.2,
        },
      },
      "1.5"
    );
    tl.fromTo(
      mediaSolutionsLetters,
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 0.3,
        ease: "sine.inOut",
        stagger: {
          grid: [1, 14],
          //   from: "edges",
          from: "random",
          amount: 0.2,
        },
      },
      "1.75"
    );
    tl.timeScale(0.8);

    return () => {
      tl.kill();
    };
  }, [theme]);

  return (
    <div className={clsx(className, classes.root, styles.root)}>
      <svg
        // width="316px"
        // height="340px"
        viewBox="0 0 316 340"
        className={styles.logo}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(-562.000000, -342.000000)" fill="#1C3148">
            <g id="mms-logo" transform="translate(562.500000, 342.500000)">
              <polygon
                id="m_1"
                ref={mRef.current[0]}
                points="157.817153 124.053554 59.4051353 41.5 59.4051353 231.80737 85.039841 231.80737 85.039841 98.2012112 158.033959 159.68214 256.22917 76.6941416 256.22917 41.5"
              ></polygon>
              <polygon
                id="m_2"
                ref={mRef.current[1]}
                points="100.681746 134.046853 100.681746 169.892246 157.817402 217.034852 230.160269 156.206004 230.160269 231.807619 256.229419 231.807619 256.229419 98.2014604 157.817402 180.971822"
              ></polygon>
              <path
                d="M87.1186944,274.865369 C87.1186944,275.742564 86.3885289,276.181162 85.803732,276.181162 L79.0071292,276.181162 C78.423163,276.181162 77.6913362,275.742564 77.6913362,274.865369 L77.6913362,253.819325 C77.6913362,249.36108 76.3763738,248.191486 72.9415226,248.191486 C71.3341617,248.191486 68.8496055,249.141781 68.8496055,249.141781 L68.8496055,274.865369 C68.8496055,275.742564 68.1186094,276.181162 67.5329818,276.181162 L60.7380404,276.181162 C60.1524128,276.181162 59.4222473,275.742564 59.4222473,274.865369 L59.4222473,243.587871 C59.4222473,241.834311 68.7034063,239.933721 71.7727594,239.933721 C75.9377761,239.933721 78.2038641,240.371488 82.1504126,243.295473 C86.0222002,239.933721 90.9190437,239.933721 91.796239,239.933721 C100.565701,239.933721 105.316345,242.564477 105.316345,257.618844 L105.316345,274.865369 C105.316345,275.742564 104.585349,276.181162 104.000552,276.181162 L97.2047798,276.181162 C96.6199829,276.181162 95.8889868,275.742564 95.8889868,274.865369 L95.8889868,253.672295 C95.8889868,249.36108 93.9152972,248.191486 90.7728444,248.191486 C89.3847824,248.191486 87.704322,248.702352 86.0961305,249.872777 C87.1186944,252.648901 87.1186944,255.499786 87.1186944,259.080836 L87.1186944,274.865369 Z"
                id="malkus_m"
                ref={malkusRef.current[0]}
              ></path>
              <path
                d="M128.410424,261.638991 L125.341071,261.638991 C122.563285,261.638991 119.056165,262.149857 119.056165,265.146111 C119.056165,268.143195 120.517327,268.94646 123.65978,268.94646 C125.998967,268.94646 128.410424,268.434763 128.410424,267.1929 L128.410424,261.638991 Z M128.775922,274.207971 C125.560369,276.474059 123.51358,276.765627 120.517327,276.765627 C116.059912,276.765627 109.628807,273.841642 109.628807,265.877107 C109.628807,256.303549 115.328915,254.184491 122.198618,254.184491 L127.825627,254.184491 L127.825627,253.745894 C127.825627,249.579215 125.706569,248.191984 121.248323,248.191984 C118.983066,248.191984 116.644709,248.484383 113.794654,249.142279 C113.429156,249.142279 112.989728,248.995249 112.77126,248.484383 L111.675596,242.417945 C111.675596,241.907079 111.967995,241.541581 112.405762,241.322282 C115.839782,240.225788 119.421663,239.93422 123.148913,239.93422 C132.210773,239.93422 137.837782,244.684033 137.837782,254.184491 L137.837782,274.865037 C137.837782,275.742232 137.107617,276.18083 136.521989,276.18083 L130.89498,276.18083 C130.310183,276.18083 129.944685,276.10773 129.506918,275.377565 L128.775922,274.207971 Z"
                id="malkus_a"
                ref={malkusRef.current[1]}
              ></path>
              <path
                d="M153.038764,274.865369 C153.038764,275.742564 152.308598,276.181162 151.722971,276.181162 L144.927199,276.181162 C144.341571,276.181162 143.611406,275.742564 143.611406,274.865369 L143.611406,231.456658 C143.611406,230.579463 144.341571,230.140865 144.927199,230.140865 L151.722971,230.140865 C152.308598,230.140865 153.038764,230.579463 153.038764,231.456658 L153.038764,274.865369 Z"
                id="malkus_l"
                ref={malkusRef.current[2]}
              ></path>
              <path
                d="M168.604164,259.519268 L168.604164,274.865203 C168.604164,275.742398 167.873998,276.180996 167.288371,276.180996 L160.492599,276.180996 C159.907802,276.180996 159.176805,275.742398 159.176805,274.865203 L159.176805,231.456492 C159.176805,230.579297 159.907802,230.140699 160.492599,230.140699 L167.288371,230.140699 C167.873998,230.140699 168.604164,230.579297 168.604164,231.456492 L168.604164,251.188404 L177.739123,241.394717 C178.251651,240.810751 178.981817,240.517521 179.346484,240.517521 L188.262976,240.517521 C188.846942,240.517521 189.43257,240.95695 189.43257,241.687946 L176.789659,255.207221 L189.943436,275.011402 C189.943436,275.742398 189.35947,276.180996 188.773843,276.180996 L180.735377,276.180996 C180.369879,276.180996 179.638883,275.888597 179.128016,275.3038 L168.604164,259.519268 Z"
                id="malkus_k"
                ref={malkusRef.current[3]}
              ></path>
              <path
                d="M201.709982,262.880521 C201.709982,267.411036 205.217933,268.50753 208.287286,268.50753 C210.480275,268.50753 213.330329,267.630335 213.330329,267.630335 L213.330329,241.834477 C213.330329,240.957282 214.060494,240.517854 214.645291,240.517854 L221.441894,240.517854 C222.026691,240.517854 222.757687,240.957282 222.757687,241.834477 L222.757687,273.258174 C222.757687,274.938635 211.649868,276.765294 207.336991,276.765294 C198.422161,276.765294 192.282624,273.258174 192.282624,258.203807 L192.282624,241.834477 C192.282624,240.957282 193.014451,240.517854 193.598417,240.517854 L200.39502,240.517854 C200.979817,240.517854 201.709982,240.957282 201.709982,241.834477 L201.709982,262.880521 Z"
                id="malkus_u"
                ref={malkusRef.current[4]}
              ></path>
              <path
                d="M242.708898,247.752722 C237.812054,247.752722 237.374287,249.94571 237.374287,250.675876 C237.374287,256.230616 256.229004,253.015063 256.229004,266.096572 C256.229004,271.358083 253.086551,276.765793 241.466204,276.765793 C234.889731,276.765793 230.431485,274.646735 228.093128,273.038543 C227.654531,272.673876 227.434401,272.308378 227.434401,271.94288 C227.434401,271.650481 227.581431,271.431182 227.654531,271.211883 L229.334991,267.046867 C229.55429,266.680538 229.919788,266.461239 230.431485,266.461239 C230.650784,266.461239 230.943183,266.534339 231.161651,266.607438 C233.720137,267.484634 237.885154,268.946626 240.882238,268.946626 C245.121185,268.946626 246.801646,268.361829 246.801646,266.169671 C246.801646,260.249433 227.87383,263.538916 227.87383,250.675876 C227.87383,242.564311 235.181299,239.933555 241.466204,239.933555 C245.705151,239.933555 249.6517,240.445253 252.429485,241.394717 C252.940352,241.614016 253.086551,241.980344 253.086551,242.345012 C253.086551,242.63741 252.940352,243.002078 252.868083,243.221377 L251.186792,248.045951 C250.967493,248.410618 250.748194,248.703017 250.236497,248.703017 C250.018029,248.703017 249.6517,248.557648 249.359301,248.484549 C247.678841,248.045951 244.828787,247.752722 242.708898,247.752722"
                id="malkus_s"
                ref={malkusRef.current[5]}
              ></path>
              <path
                d="M68.6872081,296.799157 C68.6872081,297.123122 68.4180686,297.284273 68.2020925,297.284273 L66.5581818,297.284273 C66.3430364,297.284273 66.0738969,297.123122 66.0738969,296.799157 L66.0738969,289.307278 C66.0738969,287.044512 65.3728051,286.47799 63.593494,286.47799 C62.893233,286.47799 61.3847227,287.044512 61.3847227,287.044512 L61.3847227,296.799157 C61.3847227,297.123122 61.1147526,297.284273 60.8996071,297.284273 L59.2556965,297.284273 C59.0397203,297.284273 58.7705808,297.123122 58.7705808,296.799157 L58.7705808,284.996062 C58.7705808,284.56494 62.0309898,283.972667 63.1357908,283.972667 C64.6717134,283.972667 66.0738969,284.02583 67.3930126,285.346608 C68.471232,283.972667 70.842816,283.972667 71.1659496,283.972667 C74.1306373,283.972667 75.9631118,285.10405 75.9631118,289.33469 L75.9631118,296.799157 C75.9631118,297.123122 75.6939723,297.284273 75.4779961,297.284273 L73.8340855,297.284273 C73.61894,297.284273 73.3489699,297.123122 73.3489699,296.799157 L73.3489699,289.227533 C73.3489699,287.044512 72.3521569,286.47799 70.734828,286.47799 C70.0611486,286.47799 69.3068935,286.640803 68.3100805,287.367646 C68.6872081,288.822162 68.6872081,290.062363 68.6872081,291.409722 L68.6872081,296.799157 Z"
                id="media_m"
                ref={mediaSolutionsRef.current[0]}
              ></path>
              <path
                d="M86.9581244,289.576999 C86.9581244,286.640554 84.8830922,286.478572 84.1288371,286.478572 C83.346339,286.478572 81.2713067,286.640554 81.2713067,289.576999 L86.9581244,289.576999 Z M88.2240769,294.993846 L88.494047,296.826321 C88.494047,296.906896 88.440053,297.122872 88.2514892,297.176036 C87.1998516,297.392012 85.9338992,297.5 84.6671161,297.5 C81.2713067,297.5 78.6579955,295.910083 78.6579955,291.436885 L78.6579955,289.550417 C78.6579955,288.122482 79.0085414,283.972418 84.020849,283.972418 C89.1403141,283.972418 89.5714357,288.283634 89.5714357,289.712399 L89.5714357,291.463467 C89.5714357,291.786601 89.3022962,291.948583 89.0871507,291.948583 L81.2995497,291.948583 L81.2995497,292.110565 C81.4872828,294.751288 82.916048,294.939852 85.717923,294.939852 C86.3376084,294.939852 86.9581244,294.831864 87.8469493,294.751288 C87.981519,294.751288 88.1435011,294.805282 88.2240769,294.993846 L88.2240769,294.993846 Z"
                id="media_e"
                ref={mediaSolutionsRef.current[1]}
              ></path>
              <path
                d="M100.593695,286.936524 C100.593695,286.936524 98.9497839,286.47799 97.9795527,286.47799 C95.0696896,286.47799 94.7723071,288.580435 94.7723071,290.628055 C94.7723071,292.595099 94.9077075,294.940101 97.9795527,294.940101 C99.1383477,294.940101 100.593695,294.616137 100.593695,294.616137 L100.593695,286.936524 Z M103.207006,296.395448 C103.207006,296.799157 100.108579,297.445424 97.5750128,297.445424 C94.6925621,297.445424 92.1315835,296.018321 92.1315835,290.493485 C92.1315835,285.642329 94.8537135,283.972667 96.8473393,283.972667 C97.440443,283.972667 98.7072261,283.972667 100.593695,284.618934 L100.593695,280.792834 C100.593695,280.46887 100.862834,280.307718 101.07881,280.307718 L102.722721,280.307718 C102.937866,280.307718 103.207006,280.46887 103.207006,280.792834 L103.207006,296.395448 Z"
                id="media_d"
                ref={mediaSolutionsRef.current[2]}
              ></path>
              <path
                d="M106.440916,284.618934 C106.440916,284.295801 106.710886,284.133819 106.926032,284.133819 L108.569942,284.133819 C108.785088,284.133819 109.055058,284.295801 109.055058,284.618934 L109.055058,296.799157 C109.055058,297.123122 108.785088,297.284273 108.569942,297.284273 L106.926032,297.284273 C106.710886,297.284273 106.440916,297.123122 106.440916,296.799157 L106.440916,284.618934 Z M106.225771,281.816229 C106.225771,280.846828 106.89945,280.442288 107.735112,280.442288 C108.54253,280.442288 109.243622,280.846828 109.243622,281.816229 C109.243622,282.78646 108.54253,283.191 107.735112,283.191 C106.89945,283.191 106.225771,282.78646 106.225771,281.816229 L106.225771,281.816229 Z"
                id="media_i"
                ref={mediaSolutionsRef.current[3]}
              ></path>
              <path
                d="M119.995329,291.786767 L117.839721,291.786767 C116.626932,291.786767 115.037015,292.002743 115.037015,293.511253 C115.037015,294.993182 116.115235,295.128582 117.570582,295.128582 C118.648801,295.128582 119.995329,294.83203 119.995329,294.023781 L119.995329,291.786767 Z M122.609471,296.799074 C122.609471,297.123039 122.340331,297.28419 122.124355,297.28419 L120.804409,297.28419 C120.642427,297.28419 120.453863,297.203614 120.345875,297.015051 L120.022741,296.395365 C118.755958,297.445341 117.651157,297.445341 116.546356,297.445341 C115.010434,297.445341 112.423704,296.691086 112.423704,293.484671 C112.423704,290.116274 114.472155,289.470007 116.896902,289.470007 L119.995329,289.470007 L119.995329,289.280613 C119.995329,287.205581 118.701964,286.477907 116.546356,286.477907 C115.738107,286.477907 114.902446,286.612477 114.040203,286.77529 C113.905633,286.77529 113.744481,286.720465 113.663075,286.532732 L113.339111,284.780833 C113.339111,284.592269 113.447099,284.4577 113.609912,284.376293 C114.821039,284.080572 116.033828,283.972584 117.220036,283.972584 C120.507857,283.972584 122.609471,285.670489 122.609471,289.092049 L122.609471,296.799074 Z"
                id="media_a"
                ref={mediaSolutionsRef.current[4]}
              ></path>
              <path
                d="M139.020667,284.565355 C139.020667,284.673344 138.993254,284.726507 138.966673,284.807913 L138.696702,285.642744 C138.670121,285.696738 138.588714,285.804726 138.454145,285.804726 C138.427563,285.804726 138.400981,285.804726 138.346157,285.778145 C137.726471,285.670156 136.783652,285.535587 136.109973,285.535587 C132.849564,285.535587 132.445024,286.963521 132.445024,287.529213 C132.445024,290.547064 140.745153,289.334275 140.745153,293.861467 C140.745153,295.855923 139.640352,297.44584 135.571694,297.44584 C133.441837,297.44584 131.987321,296.988137 130.936514,296.421615 C130.828526,296.368451 130.774532,296.287045 130.774532,296.179057 C130.774532,296.071899 130.801113,296.017905 130.828526,295.936499 L131.097665,295.12908 C131.125078,295.074256 131.205653,294.966268 131.340223,294.966268 C131.366805,294.966268 131.394217,294.966268 131.448211,294.99368 C132.499018,295.478796 133.711807,295.883336 135.355718,295.883336 C137.996441,295.883336 139.101242,295.424802 139.101242,293.888879 C139.101242,290.574476 130.801113,292.163562 130.801113,287.421225 C130.801113,284.834495 133.388674,283.972252 135.543451,283.972252 C136.676495,283.972252 137.834459,284.106822 138.858685,284.322798 C138.966673,284.375961 139.020667,284.457367 139.020667,284.565355"
                id="solutions_s1"
                ref={mediaSolutionsRef.current[5]}
              ></path>
              <path
                d="M154.299732,292.299378 L154.299732,289.119545 C154.299732,286.936524 152.76381,285.535171 150.71619,285.535171 C148.66857,285.535171 147.131817,286.936524 147.131817,289.119545 L147.131817,292.299378 C147.131817,294.481567 148.66857,295.88292 150.71619,295.88292 C152.76381,295.88292 154.299732,294.481567 154.299732,292.299378 M145.488737,291.598286 L145.488737,289.819806 C145.488737,285.400602 147.832908,283.972667 150.71619,283.972667 C153.599471,283.972667 155.943643,285.400602 155.943643,289.819806 L155.943643,291.598286 C155.943643,296.018321 153.599471,297.445424 150.71619,297.445424 C147.832908,297.445424 145.488737,296.018321 145.488737,291.598286"
                id="solutions_o"
                ref={mediaSolutionsRef.current[6]}
              ></path>
              <path
                d="M163.677333,296.960641 C163.677333,297.176617 163.516181,297.284605 163.354199,297.284605 L162.357386,297.284605 C162.195404,297.284605 162.033422,297.176617 162.033422,296.960641 L162.033422,280.631184 C162.033422,280.415208 162.195404,280.30722 162.357386,280.30722 L163.354199,280.30722 C163.516181,280.30722 163.677333,280.415208 163.677333,280.631184 L163.677333,296.960641 Z"
                id="solutions_l"
                ref={mediaSolutionsRef.current[7]}
              ></path>
              <path
                d="M172.085533,292.218304 C172.085533,295.182161 174.268553,295.883253 176.235597,295.883253 C177.394392,295.883253 179.118879,295.694689 179.657988,295.424719 L179.657988,284.457284 C179.657988,284.241308 179.81997,284.134151 179.981122,284.134151 L180.978765,284.134151 C181.139917,284.134151 181.301899,284.241308 181.301899,284.457284 L181.301899,296.66492 C181.301899,296.988053 178.094653,297.445757 175.99304,297.445757 C172.301509,297.445757 170.441622,295.883253 170.441622,291.517212 L170.441622,284.457284 C170.441622,284.241308 170.603604,284.134151 170.765586,284.134151 L171.762399,284.134151 C171.924381,284.134151 172.085533,284.241308 172.085533,284.457284 L172.085533,292.218304 Z"
                id="solutions_u"
                ref={mediaSolutionsRef.current[8]}
              ></path>
              <path
                d="M189.816924,284.133819 L189.816924,282.140193 L190.03207,280.630852 C190.059482,280.414876 190.194052,280.307718 190.355203,280.307718 L191.190865,280.307718 C191.352847,280.307718 191.513998,280.414876 191.513998,280.630852 L191.513998,284.133819 L194.316704,284.133819 C194.53268,284.133819 194.640668,284.295801 194.640668,284.457783 L194.640668,285.373189 C194.640668,285.535171 194.53268,285.697154 194.316704,285.697154 L191.513998,285.697154 L191.513998,292.649093 C191.513998,294.185846 191.918538,295.883751 194.074146,295.883751 C194.155552,295.883751 194.53268,295.855508 194.613256,295.855508 C194.828401,295.855508 194.909808,296.018321 194.909808,296.15289 L194.909808,297.068297 C194.909808,297.176285 194.80182,297.338267 194.613256,297.392261 C194.290122,297.445424 193.912995,297.445424 193.670437,297.445424 C191.541411,297.445424 189.816924,296.448612 189.816924,293.081045 L189.816924,285.697154 L187.17537,285.697154 C186.960225,285.697154 186.852236,285.535171 186.852236,285.373189 L186.852236,284.457783 C186.852236,284.295801 186.960225,284.133819 187.17537,284.133819 L189.816924,284.133819 Z"
                id="solutions_t"
                ref={mediaSolutionsRef.current[9]}
              ></path>
              <path
                d="M203.047705,296.960641 C203.047705,297.176617 202.886554,297.284605 202.724572,297.284605 L201.727759,297.284605 C201.565777,297.284605 201.403795,297.176617 201.403795,296.960641 L201.403795,284.457284 C201.403795,284.241308 201.565777,284.134151 201.727759,284.134151 L202.724572,284.134151 C202.886554,284.134151 203.047705,284.241308 203.047705,284.457284 L203.047705,296.960641 Z M201.161237,282.166276 C201.161237,281.520009 201.646352,281.250869 202.212874,281.250869 C202.778566,281.250869 203.263681,281.520009 203.263681,282.166276 C203.263681,282.814204 202.778566,283.083344 202.212874,283.083344 C201.646352,283.083344 201.161237,282.814204 201.161237,282.166276 L201.161237,282.166276 Z"
                id="solutions_i"
                ref={mediaSolutionsRef.current[10]}
              ></path>
              <path
                d="M217.94956,292.299378 L217.94956,289.119545 C217.94956,286.936524 216.413637,285.535171 214.366018,285.535171 C212.318398,285.535171 210.781644,286.936524 210.781644,289.119545 L210.781644,292.299378 C210.781644,294.481567 212.318398,295.88292 214.366018,295.88292 C216.413637,295.88292 217.94956,294.481567 217.94956,292.299378 M209.138564,291.598286 L209.138564,289.819806 C209.138564,285.400602 211.482736,283.972667 214.366018,283.972667 C217.249299,283.972667 219.593471,285.400602 219.593471,289.819806 L219.593471,291.598286 C219.593471,296.018321 217.249299,297.445424 214.366018,297.445424 C211.482736,297.445424 209.138564,296.018321 209.138564,291.598286"
                id="solutions_o2"
                ref={mediaSolutionsRef.current[11]}
              ></path>
              <path
                d="M234.361171,289.199788 C234.361171,286.235931 232.178151,285.53567 230.211107,285.53567 C229.052312,285.53567 227.327825,285.723403 226.788716,285.993373 L226.788716,296.960807 C226.788716,297.176783 226.626733,297.283941 226.465582,297.283941 L225.468769,297.283941 C225.306787,297.283941 225.144805,297.176783 225.144805,296.960807 L225.144805,284.753172 C225.144805,284.430869 228.352051,283.972335 230.453664,283.972335 C234.145195,283.972335 236.005082,285.53567 236.005082,289.90088 L236.005082,296.960807 C236.005082,297.176783 235.8431,297.283941 235.681948,297.283941 L234.684305,297.283941 C234.522323,297.283941 234.361171,297.176783 234.361171,296.960807 L234.361171,289.199788 Z"
                id="solutions_n"
                ref={mediaSolutionsRef.current[12]}
              ></path>
              <path
                d="M250.341162,284.565355 C250.341162,284.673344 250.31375,284.726507 250.287168,284.807913 L250.017198,285.642744 C249.990616,285.696738 249.90921,285.804726 249.77464,285.804726 C249.748058,285.804726 249.721477,285.804726 249.666652,285.778145 C249.046967,285.670156 248.104148,285.535587 247.430468,285.535587 C244.170059,285.535587 243.76552,286.963521 243.76552,287.529213 C243.76552,290.547064 252.065649,289.334275 252.065649,293.861467 C252.065649,295.855923 250.960848,297.44584 246.892189,297.44584 C244.762332,297.44584 243.307816,296.988137 242.257009,296.421615 C242.149021,296.368451 242.095027,296.287045 242.095027,296.179057 C242.095027,296.071899 242.121609,296.017905 242.149021,295.936499 L242.418161,295.12908 C242.445573,295.074256 242.526149,294.966268 242.660719,294.966268 C242.6873,294.966268 242.714713,294.966268 242.768707,294.99368 C243.819514,295.478796 245.032303,295.883336 246.676213,295.883336 C249.316937,295.883336 250.421738,295.424802 250.421738,293.888879 C250.421738,290.574476 242.121609,292.163562 242.121609,287.421225 C242.121609,284.834495 244.709169,283.972252 246.863946,283.972252 C247.99699,283.972252 249.154955,284.106822 250.17918,284.322798 C250.287168,284.375961 250.341162,284.457367 250.341162,284.565355"
                id="solutions_s2"
                ref={mediaSolutionsRef.current[13]}
              ></path>
              <rect
                id="Rectangle"
                x="0"
                y="0"
                width="315"
                height="339"
                fill="none"
              ></rect>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
