import {
  createMuiTheme,
  CssBaseline,
  Fade,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import LandingPage from "./pages/LandingPage";
import Privacy from "./pages/Privacy";
import Imprint from "./pages/Imprint";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: { main: "#CCB593" },
      secondary: { main: "#1C3148" },
      text: {
        primary: "#1C3148",
        secondary: "#CCB593",
      },
      type: "light",
    },
    borderRadius: 15,
    spacing: 10,
    overrides: {
      MuiButton: {
        root: { borderRadius: 15, textTransform: "none" },
      },
    },
    typography: {
      fontFamily: ["Nunito", "Arial", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontWeight: 600,
      },
    },
  })
);

const useStyles = makeStyles(
  (_theme) => ({
    root: (props) => ({
      textAlign: "center",
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "stretch",
      margin: "30px",
      border: `solid 4px ${_theme.palette.primary.main}`,
      borderRadius: "5px",
      overflow: "visible",
    }),
  }),
  { name: "App" }
);

function App(props) {
  const { className } = props;
  const classes = props.classes || {};
  const styles = useStyles(props);

  return (
    <Router>
      <Fade in>
        <div className={clsx(className, classes.root, styles.root)}>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Switch>
              <Route path="/imprint">
                <Imprint />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/">
                <LandingPage />
              </Route>
            </Switch>
          </div>

          <Footer />
        </div>
      </Fade>
    </Router>
  );
}

export default function AppWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
}
