import { makeStyles, IconButton, Zoom } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(
  (_theme) => ({
    root: (props) => ({
      textAlign: "left",
      padding: _theme.spacing(2),
      wordWrap: "break-word",
      position: "relative",
    }),
    source: (props) => ({ color: _theme.palette.text.disabled }),
    source_link: (props) => ({
      marginLeft: 5,
      color: _theme.palette.text.disabled,
    }),
    closeButton: {
      position: "absolute",
      top: _theme.spacing(1),
      right: _theme.spacing(1),
    },
  }),
  { name: "Imprint" }
);

export default function Imprint(props) {
  const { className } = props;
  const classes = props.classes || {};
  const styles = useStyles(props);

  return (
    <Zoom in>
      <div className={clsx(className, classes.root, styles.root)}>
        <IconButton
          size="medium"
          color="secondary"
          component={Link}
          to="/"
          className={styles.closeButton}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <Typography variant="h6">Impressum</Typography>
        <p>
          Sven Malkus
          <br />
          malkus media solutions
          <br />
          Auf dem Höchsten 13
          <br />
          58638 Iserlohn
        </p>
        <p>
          <br />
          E-Mail: <a href="mailto:info@malkus-media.de">info@malkus-media.de</a>
          <br />
        </p>
        <p>
          <strong>Umsatzsteuer-ID: </strong>
          <br />
          Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:
          <br />
          DE251857771
        </p>
      </div>
    </Zoom>
  );
}
