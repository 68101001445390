import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(
  (_theme) => ({
    root: (props) => ({
      display: "flex",
      flexDirection: "row",
      padding: 2,
      flexWrap: "wrap",
    }),
  }),
  { name: "Footer" }
);

export default function Footer(props) {
  const { className } = props;
  const classes = props.classes || {};
  const styles = useStyles(props);

  return (
    <div className={clsx(className, classes.root, styles.root)}>
      {/* <SvenMalkus /> */}
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Button size="small" color="primary" component={Link} to="/privacy">
          Datenschutz
        </Button>
        <Button size="small" color="primary" component={Link} to="/imprint">
          Impressum
        </Button>
      </div>
    </div>
  );
}
